<template>
    <div class="page detail iphone-x-adaptation" :class="{ takeout: pageType == 1 }">
        <template>
            <img class="product-img" :src="productImageUrl" referrerPolicy="no-referrer" />
            <div class="product-name">{{ productName }}</div>
            <div class="product-desc" v-if="descCn" v-html="descCn"></div>
            <div class="product-box" v-if="items.length > 0">
                <div class="title">已选餐品</div>
                <div class="product-list iphone-x-adaptation">
                    <div class="product-item" v-for="(item, i) in items" :key="i">
                        <img class="img" :src="item.productImageUrl" />
                        <div class="name">
                            <span class="group-name" v-if="item.groupName">
                                {{ item.groupName }}:
                            </span>
                            {{ item.productName }}
                        </div>
                        <div class="num">{{ item.quantity || 1 }}份</div>
                    </div>
                </div>
            </div>
            <div class="spec-group-box" v-if="specGroups.length > 0">
                <div class="title">可选餐品</div>
                <div class="product-list iphone-x-adaptation">
                    <div class="spec-group-item">
                        <div
                            class="spec-group-name"
                            @click="switchSku(i)"
                            :class="{ select: i == specIndex }"
                            v-for="(item, i) in specGroups"
                            :key="i"
                        >
                            {{ item.productName }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-detail-list">
                <img :src="item" :key="item" v-for="item in productDetailImageUrls" />
            </div>
            <div class="padding-bottom"></div>
        </template>
        <!-- <template v-else-if="pageType == 1">
            <img class="product-img" :src="productImageUrl" referrerPolicy="no-referrer" />
            <div class="product-name">{{ productName }}</div>
            <div class="product-desc" v-if="descCn" v-html="descCn"></div>
            <div class="product-box" v-if="type == 2">
                <div class="title">产品</div>
                <div class="group-list">
                    <div
                        class="group-item"
                        v-for="(item, index) in takeoutOriginalProduct.groupItems"
                        :class="{ select: index == takeoutSelectGroupIndex }"
                        :key="index"
                        @click="switchGroupProduct(index)"
                    >
                        {{ item.productName }}
                    </div>
                </div>
            </div>
            <div class="product-box" v-if="takeoutProductInfo.mandatory.length > 0">
                <div class="title">已选餐品</div>
                <div class="product-list iphone-x-adaptation">
                    <div
                        class="product-item"
                        v-for="(item, i) in takeoutProductInfo.mandatory"
                        :key="i"
                    >
                        <img class="img" :src="item.condimentItemList[0].imageUrl" />
                        <div class="name">
                            <span class="group-name" v-if="item.groupName">
                                {{ item.groupName }}:
                            </span>
                            {{ item.condimentItemList[0].productName }}
                        </div>
                        <div class="num">{{ item.condimentItemList[0].quantity || 1 }}份</div>
                    </div>
                </div>
            </div>
            <div
                class="product-box"
                v-for="(groupItem, index) in takeoutProductInfo.need2Select"
                :key="index"
            >
                <div class="title">
                    {{ groupItem.groupName
                    }}{{
                        groupItem.maxItemCount == groupItem.minItemCount
                            ? `(请选择${groupItem.maxItemCount}份)`
                            : `(请选择${groupItem.minItemCount}~${groupItem.maxItemCount}份)`
                    }}
                </div>
                <div class="product-list iphone-x-adaptation">
                    <div
                        class="product-item"
                        v-for="(item, i) in groupItem.condimentItemList"
                        :key="new Date().getTime() + i"
                    >
                        <img class="img" :src="item.imageUrl" />
                        <div class="name">
                            <p>{{ item.productName }}</p>
                            <p class="price" v-if="item.price">加¥{{ item.price }}</p>
                        </div>
                        <div class="quantity-box" v-if="!item.saleOut">
                            <img
                                class="reduce"
                                @click="reduceGroupNum('need2Select', index, i)"
                                v-if="item.count > 0"
                                src="@/assets/icons/icon-circular-white-reduce.png"
                            />
                            <div class="quantity" v-if="item.count > 0">{{ item.count }}</div>
                            <img
                                class="plus"
                                :class="{
                                    max: groupItem.selectStatus,
                                }"
                                @click="plusGroupNum('need2Select', index, i)"
                                src="@/assets/icons/icon-circular-plus.png"
                            />
                        </div>
                        <div class="sale-out" v-else>已售罄</div>
                    </div>
                </div>
            </div>
            <div
                class="product-box"
                v-for="(groupItem, index) in takeoutProductInfo.optional"
                :key="'group' + index"
            >
                <div class="title">{{ groupItem.groupName }}</div>
                <div class="product-list iphone-x-adaptation">
                    <div
                        class="product-item"
                        v-for="(item, i) in groupItem.condimentItemList"
                        :key="i"
                    >
                        <img class="img" :src="item.imageUrl" />
                        <div class="name">
                            <p>{{ item.productName }}</p>
                            <p class="price" v-if="item.price">加¥{{ item.price }}</p>
                        </div>
                        <div class="quantity-box" v-if="!item.saleOut">
                            <img
                                class="reduce"
                                @click="reduceGroupNum('optional', index, i)"
                                v-if="item.count > 0"
                                src="@/assets/icons/icon-circular-white-reduce.png"
                            />
                            <div class="quantity" v-if="item.count > 0">{{ item.count }}</div>
                            <img
                                class="plus"
                                :class="{
                                    max: groupItem.selectStatus,
                                }"
                                @click="plusGroupNum('optional', index, i)"
                                src="@/assets/icons/icon-circular-plus.png"
                            />
                        </div>
                        <div class="sale-out" v-else>已售罄</div>
                    </div>
                </div>
            </div>
            <div class="padding-bottom"></div>
        </template> -->
        <div class="cart-box iphone-x-adaptation open-cart" v-if="type == 50">
            <div class="cart-line">
                <div class="cart-price" @click="toIndex">
                    <div class="content">
                        <div class="price-box">
                            <div class="price">
                                <div class="text">¥{{ total }}</div>
                                <div class="money-discount" v-if="reducePriceTotal > 0">
                                    已省¥{{ reducePriceTotal }}元
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn" @click="addCommit">
                            <div class="title">立即购买</div>
                            <div class="tip">Buy</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-box iphone-x-adaptation open-cart" v-else>
            <div class="quantity-line">
                <div class="quantity-box">
                    <img
                        class="reduce"
                        @click="reduceNum"
                        src="~@/assets/icons/icon-circular-white-reduce.png"
                    />
                    <div class="quantity">{{ num }}</div>
                    <img
                        class="plus"
                        :class="{ max: num >= maxQty && maxQty != -1 }"
                        @click="plusNum"
                        src="~@/assets/icons/icon-circular-plus.png"
                    />
                </div>
            </div>
            <div class="cart-line">
                <div class="cart-price">
                    <div class="content">
                        <div class="price-box">
                            <div class="price">
                                <div class="text">¥{{ total }}</div>
                                <div class="money-discount" v-if="reducePriceTotal > 0">
                                    已省¥{{ reducePriceTotal }}元
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn" @click="addCart">
                            <div class="title">加入购物车</div>
                            <div class="tip">Add Cart</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="page detail iphone-x-adaptation" v-if="pageType == 0">
        <div class="bottom iphone-x-adaptation">
            <div class="price-line" v-if="$store.getters.getExposeMethod.showOriginalPrice">
                <div class="price-text">到手价</div>
                <div class="total">{{ total }}</div>
                <div class="actual-payment">
                    实付¥{{ originalTotal }}元，到手后返现¥{{ reducePriceTotal }}元
                </div>
                <div class="quantity-box">
                    <img
                        class="reduce"
                        @click="reduceNum"
                        src="@/assets/icons/icon-circular-white-reduce.png"
                    />
                    <div class="num">{{ num }}</div>
                    <img
                        class="plus"
                        :class="{ max: num >= maxQty && maxQty != -1 }"
                        @click="plusNum"
                        src="@/assets/icons/icon-circular-plus.png"
                    />
                </div>
            </div>
            <div class="price-line" v-else>
                <div class="total">
                    {{ total
                    }}{{ xiaoBuDianIntegralTotal ? '+' + xiaoBuDianIntegralTotal + '积分' : ''
                    }}{{ jiMiWuXianIntegralTotal ? '+' + jiMiWuXianIntegralTotal + '能量' : '' }}
                </div>
                <div class="quantity-box">
                    <img
                        class="reduce"
                        @click="reduceNum"
                        src="@/assets/icons/icon-circular-white-reduce.png"
                    />
                    <div class="num">{{ num }}</div>
                    <img
                        class="plus"
                        :class="{ max: num >= maxQty && maxQty != -1 }"
                        @click="plusNum"
                        src="@/assets/icons/icon-circular-plus.png"
                    />
                </div>
            </div>
            <div class="btn-submit iphone-x-adaptation" @click="addCart">加入购物车</div>
        </div>
    </div> -->
    <!-- <div class="page detail takeout iphone-x-adaptation" v-else-if="pageType == 1">
        <div class="bottom iphone-x-adaptation" :class="{ 'takeout-select-no': !takeoutOkStatus }">
            <div class="price-line" v-if="$store.getters.getExposeMethod.showOriginalPrice">
                <div class="price-text">到手价</div>
                <div class="total">{{ total }}</div>
                <div class="actual-payment">
                    实付¥{{ originalTotal }}元，到手后返现¥{{ reducePriceTotal }}元
                </div>
                <div class="quantity-box">
                    <img
                        class="reduce"
                        @click="reduceNum"
                        src="@/assets/icons/icon-circular-white-reduce.png"
                    />
                    <div class="num">{{ num }}</div>
                    <img
                        class="plus"
                        :class="{ max: num >= maxQty && maxQty != -1 }"
                        @click="plusNum"
                        src="@/assets/icons/icon-circular-plus.png"
                    />
                </div>
            </div>
            <div class="price-line" v-else>
                <div class="total">
                    {{ total
                    }}{{ xiaoBuDianIntegralTotal ? '+' + xiaoBuDianIntegralTotal + '积分' : ''
                    }}{{ jiMiWuXianIntegralTotal ? '+' + jiMiWuXianIntegralTotal + '能量' : '' }}
                </div>
                <div class="quantity-box">
                    <img
                        class="reduce"
                        @click="reduceNum"
                        src="@/assets/icons/icon-circular-white-reduce.png"
                    />
                    <div class="num">{{ num }}</div>
                    <img
                        class="plus"
                        :class="{ max: num >= maxQty && maxQty != -1 }"
                        @click="plusNum"
                        src="@/assets/icons/icon-circular-plus.png"
                    />
                </div>
            </div>
            <div class="btn-submit iphone-x-adaptation" @click="addCart">加入购物车</div>
        </div>
    </div> -->
</template>
<script>
import { ADD_PRODUCT_FOR_CART } from 'api';
import { QZ_KFC_CART_NAME, QZ_KFC_PRODUCT_INFO } from 'constant';
import number from '@/lib/number';
export default {
    data() {
        return {
            type: '',
            storeCode: '',
            productId: '',
            accountId: '',
            accountType: '',
            _productId: '',
            productName: '',
            productImageUrl: '',
            originalTotalPrice: '',
            descCn: '',
            price: '',
            productDetailImageUrls: [],
            items: [],
            specGroups: [],
            specIndex: -1,
            num: 1,
            maxQty: -1,
            reduce_price: '',
            limitActivity: null,
            purchasedQuantity: 0,
            addressId: '',
            takeoutOriginalProduct: {},
            takeoutSelectGroupIndex: -1,
            takeoutProductInfo: {
                mandatory: [],
                need2Select: [],
                optional: [],
            },
            takeoutOkStatus: false,
        };
    },
    computed: {
        originalTotal() {
            if (this.specIndex == -1) {
                return number.operation(`${this.originalTotalPrice}*${this.num}`);
            } else {
                return number.operation(
                    `${this.specGroups[this.specIndex].originalTotalPrice}*${this.num}`
                );
            }
        },
        total() {
            if (this.specIndex == -1) {
                return +(+number.operation(`${this.price}*${this.num}`)).toFixed(2);
            } else {
                return +number.operation(`${this.specGroups[this.specIndex].price}*${this.num}`);
            }
        },
        xiaoBuDianIntegralTotal() {
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                if (this.specIndex == -1) {
                    let reduce = number.operation(`${this.originalTotalPrice}-${this.price}`);
                    return +number.operation(`${reduce}*${this.num * 20}`);
                } else {
                    let reduce = number.operation(
                        `${this.specGroups[this.specIndex].originalTotalPrice}-${
                            this.specGroups[this.specIndex].price
                        }`
                    );
                    return +number.operation(`${reduce}*${this.num * 20}`);
                }
            } else {
                return 0;
            }
        },
        jiMiWuXianIntegralTotal() {
            if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                if (this.specIndex == -1) {
                    let reduce = number.operation(`${this.originalTotalPrice}-${this.price}`);
                    return +number.operation(`${reduce}*${this.num * 10}`);
                } else {
                    let reduce = number.operation(
                        `${this.specGroups[this.specIndex].originalTotalPrice}-${
                            this.specGroups[this.specIndex].price
                        }`
                    );
                    return +number.operation(`${reduce}*${this.num * 10}`);
                }
            } else {
                return 0;
            }
        },
        reducePriceTotal() {
            if (this.specIndex == -1) {
                return +number.operation(`${this.reduce_price}*${this.num}`);
            } else {
                var item = this.specGroups[this.specIndex];
                var reduce_price = number.operation(`${item.originalTotalPrice}-${item.price}`);
                return +number.operation(`${reduce_price}*${this.num}`);
            }
        },
        takeout() {
            return this.pageType == 1;
        },
    },
    created() {
        var productInfo = JSON.parse(window.localStorage.getItem(QZ_KFC_PRODUCT_INFO));
        this.pageType = productInfo.pageType || 0;
        this.type = productInfo.type;
        this.productId = productInfo.productId;
        this._productId = productInfo._productId;
        this.addressId = productInfo.addressId;
        this.productName = productInfo.productName;
        this.productImageUrl = productInfo.productImageUrl;
        this.descCn = productInfo.descCn;
        this.productDetailImageUrls = productInfo.productDetailImageUrls || [];
        if (this.type == 3) {
            this.maxQty = Number(productInfo.maxQty) || -1;
        }
        if (this.type == 2 || this.type == 3) {
            this.price = productInfo.price;
            this.reduce_price = productInfo.reduce_price || '';
            this.originalTotalPrice = productInfo.originalTotalPrice || '';
            this.getTakeoutProduct();
            return;
        } else if (this.type == 1) {
            this.takeoutOkStatus = true;
        } else if (this.type == 10) {
            this.accountId = productInfo.accountId;
            this.accountType = productInfo.accountType;
        }
        this.storeCode = productInfo.storeCode;
        this.specGroups = productInfo.specGroups;
        console.log(productInfo);
        if (this.specGroups.length > 0) {
            this.specIndex = 0;
        }
        this.price =
            this.specGroups.length > 0 ? this.specGroups[this.specIndex].price : productInfo.price;

        this.productId =
            this.specGroups.length > 0
                ? this.specGroups[this.specIndex].productId
                : productInfo.productId;
        this.items = productInfo.items || [];
        this.num = 1;
        this.maxQty = Number(productInfo.maxQty) || -1;
        this.reduce_price = productInfo.reduce_price || '';
        this.limitActivity = productInfo.limitActivity || null;
        this.purchasedQuantity = productInfo.purchasedQuantity || 0;
        this.originalTotalPrice = productInfo.originalTotalPrice || '';
        this.xiaoBuDianIntegral = productInfo.xiaoBuDianIntegral || 0;
    },
    methods: {
        getTakeoutProduct() {
            this.$util
                .post('/api/kfcMenu/getTakeoutProduct', {
                    addressId: this.addressId,
                    productId: this.productId,
                    productType: this.type,
                })
                .then(res => {
                    if (res.success) {
                        if (res.data.productType == 3) {
                            this.takeoutOriginalProduct = Object.assign({}, res.data, {
                                price: this.price,
                                originalTotalPrice: this.originalTotalPrice,
                            });
                            this.takeoutProductInfo = {
                                mandatory: [],
                                need2Select: [],
                                optional: [],
                            };
                            this.maxQty = res.data.dynamicProduct.maxQty || this.maxQty || 99;
                            for (
                                let i = 0;
                                i < res.data.dynamicProduct.condimentRoundList.length;
                                i++
                            ) {
                                const item = res.data.dynamicProduct.condimentRoundList[i];
                                this.takeoutProductClassification(item);
                            }
                            this.inspectTakeoutProductSelectStatus();
                        } else if (res.data.productType == 2) {
                            this.takeoutOriginalProduct = res.data;
                            for (let i = 0; i < res.data.groupItems.length; i++) {
                                const item = res.data.groupItems[i];
                                if (!item.saleOut) {
                                    this.takeoutSelectGroupIndex = i;
                                    break;
                                }
                            }

                            if (this.takeoutSelectGroupIndex == -1) {
                                return this.$toast('该商品已售罄');
                            }

                            this.takeoutProductInfo = {
                                mandatory: [],
                                need2Select: [],
                                optional: [],
                            };
                            let groupItem = res.data.groupItems[this.takeoutSelectGroupIndex];
                            this.maxQty = groupItem.maxQty || 99;
                            if (groupItem.type == 1) {
                                this.takeoutOkStatus = true;
                                this.price = groupItem.price;
                                this.originalTotalPrice = groupItem.originalPrice;
                            } else {
                                for (let i = 0; i < groupItem.condimentRoundList.length; i++) {
                                    const item = groupItem.condimentRoundList[i];
                                    this.takeoutProductClassification(item);
                                }
                                this.inspectTakeoutProductSelectStatus();
                            }
                        }
                    }
                });
        },
        // 外卖商品分类
        takeoutProductClassification(item) {
            // 可选
            if (item.minItemCount == 0) {
                console.log('可选', item);
                let tempSetCount = false;
                for (let j = 0; j < item.condimentItemList.length; j++) {
                    const v = item.condimentItemList[j];
                    v.count = 0;
                    v.quantity = v.count;
                }
                this.takeoutProductInfo.optional.push(item);
            }
            // 必须
            else if (item.minItemCount == item.maxItemCount && item.condimentItemList.length == 1) {
                item.condimentItemList[0].count = item.condimentItemList[0].quantity;
                this.takeoutProductInfo.mandatory.push(item);
                console.log('必选', item);
            }
            // 有数量的可选
            else {
                let tempSetCount = false;
                for (let j = 0; j < item.condimentItemList.length; j++) {
                    const v = item.condimentItemList[j];
                    if (!v.saleOut && !tempSetCount) {
                        v.count = v.quantity;
                        tempSetCount = true;
                    } else {
                        v.count = 0;
                        v.quantity = v.count;
                    }
                }
                this.takeoutProductInfo.need2Select.push(item);
                console.log('需选择', item);
            }
        },
        // 检查状态
        inspectTakeoutProductSelectStatus() {
            let tempTakeoutSelectOKStatus = true;
            for (let i = 0; i < this.takeoutProductInfo.need2Select.length; i++) {
                const groupItem = this.takeoutProductInfo.need2Select[i];
                let tempCount = 0;
                for (let j = 0; j < groupItem.condimentItemList.length; j++) {
                    const item = groupItem.condimentItemList[j];
                    tempCount += item.count;
                }
                if (tempCount >= groupItem.maxItemCount) {
                    groupItem.selectStatus = true;
                } else {
                    groupItem.selectStatus = false;
                    tempTakeoutSelectOKStatus = false;
                }
            }
            for (let i = 0; i < this.takeoutProductInfo.optional.length; i++) {
                const groupItem = this.takeoutProductInfo.optional[i];
                let tempCount = 0;
                for (let j = 0; j < groupItem.condimentItemList.length; j++) {
                    const item = groupItem.condimentItemList[j];
                    tempCount += item.count;
                }
                if (tempCount >= groupItem.maxItemCount) {
                    groupItem.selectStatus = true;
                } else {
                    groupItem.selectStatus = false;
                }
            }
            this.takeoutOkStatus = tempTakeoutSelectOKStatus;
            this.setTakeoutPrice();
        },
        // 计算价格
        setTakeoutPrice() {
            let tempAdditionalPrice = 0;
            let tempOriginPrice = 0;
            this.originalTotalPrice = this.takeoutOriginalProduct.originalTotalPrice;
            for (let i = 0; i < this.takeoutProductInfo.need2Select.length; i++) {
                const groupItem = this.takeoutProductInfo.need2Select[i];

                for (let j = 0; j < groupItem.condimentItemList.length; j++) {
                    const item = groupItem.condimentItemList[j];
                    if (item.count) {
                        let tempTotal = number.operation(`${item.price}*${item.count}`);
                        let tempOriginTotal = number.operation(`${item.originPrice}*${item.count}`);
                        console.log(item, `${item.originPrice}*${item.count}`);
                        console.log(this.reduce_price);
                        tempAdditionalPrice = number.operation(
                            `${tempTotal}+${tempAdditionalPrice}`
                        );
                        tempOriginPrice = number.operation(`${tempOriginPrice}+${tempOriginTotal}`);
                        console.log('tempOriginPrice', tempOriginPrice);
                    }
                }
            }

            for (let i = 0; i < this.takeoutProductInfo.optional.length; i++) {
                const groupItem = this.takeoutProductInfo.optional[i];

                for (let j = 0; j < groupItem.condimentItemList.length; j++) {
                    const item = groupItem.condimentItemList[j];
                    if (item.count) {
                        let tempTotal = number.operation(`${item.price}*${item.count}`);
                        let tempOriginTotal = number.operation(`${item.originPrice}*${item.count}`);
                        tempAdditionalPrice = number.operation(
                            `${tempTotal}+${tempAdditionalPrice}`
                        );
                        tempOriginPrice = number.operation(`${tempOriginPrice}+${tempOriginTotal}`);
                        console.log('tempOriginPrice', tempOriginPrice);
                    }
                }
            }
            if (this.takeoutOriginalProduct.productType == 3) {
                console.log(tempAdditionalPrice);
                this.price = number.operation(
                    `${this.takeoutOriginalProduct.price}+${tempAdditionalPrice}`
                );
                this.originalTotalPrice = number.operation(
                    `${this.originalTotalPrice}+${tempOriginPrice}`
                );
            } else if (this.takeoutOriginalProduct.productType == 2) {
                this.price = number.operation(
                    `${
                        this.takeoutOriginalProduct.groupItems[this.takeoutSelectGroupIndex].price
                    }+${tempAdditionalPrice}`
                );

                this.originalTotalPrice = number.operation(
                    `${
                        this.takeoutOriginalProduct.groupItems[this.takeoutSelectGroupIndex]
                            .originalTotalPrice
                    }+${tempOriginPrice}`
                );
            }
            console.log(this.originalTotalPrice, this.price);
            this.reduce_price = number.operation(`${this.originalTotalPrice}-${this.price}`);

            this.$forceUpdate();
        },
        // 减少需选择数量
        reduceGroupNum(type, i, j, num = 1) {
            if (type == 'need2Select') {
                this.takeoutProductInfo.need2Select[i].condimentItemList[j].count -= num;
                this.takeoutProductInfo.need2Select[i].condimentItemList[j].count =
                    this.takeoutProductInfo.need2Select[i].condimentItemList[j].count || 0;
                this.inspectTakeoutProductSelectStatus();
            } else if (type == 'optional') {
                this.takeoutProductInfo.optional[i].condimentItemList[j].count -= num;
                this.takeoutProductInfo.optional[i].condimentItemList[j].count =
                    this.takeoutProductInfo.optional[i].condimentItemList[j].count || 0;
                this.inspectTakeoutProductSelectStatus();
            }
        },
        // 增加需选择数量
        plusGroupNum(type, i, j, num = 1) {
            if (type == 'need2Select') {
                let tempCount = 0;
                for (
                    let k = 0;
                    k < this.takeoutProductInfo.need2Select[i].condimentItemList.length;
                    k++
                ) {
                    const item = this.takeoutProductInfo.need2Select[i].condimentItemList[k];
                    tempCount += item.count;
                }

                if (this.takeoutProductInfo.need2Select[i].maxItemCount > tempCount) {
                    this.takeoutProductInfo.need2Select[i].condimentItemList[j].count += num;
                    this.inspectTakeoutProductSelectStatus();
                }
            } else if (type == 'optional') {
                let tempCount = 0;
                for (
                    let k = 0;
                    k < this.takeoutProductInfo.optional[i].condimentItemList.length;
                    k++
                ) {
                    const item = this.takeoutProductInfo.optional[i].condimentItemList[k];
                    tempCount += item.count;
                }
                if (this.takeoutProductInfo.optional[i].maxItemCount > tempCount) {
                    this.takeoutProductInfo.optional[i].condimentItemList[j].count += num;
                    this.inspectTakeoutProductSelectStatus();
                }
            }
        },
        switchSku(i) {
            this.specIndex = i;
            this.productId = this.specGroups[i].productId;
            this.productImageUrl = this.specGroups[i].productImageUrl;
            this.specGroups[i].descCn && (this.descCn = this.specGroups[i].descCn);
            this.price = this.specGroups[i].price;
        },
        // 减少数量
        reduceNum() {
            if (this.num > 1) {
                this.num--;
            }
        },
        // 增加数量
        plusNum() {
            if (this.num >= this.maxQty && this.maxQty != -1) {
                return this.$toast('亲～已经加到最多了');
            }
            if (
                this.limitActivity &&
                this.limitActivity.totalEveryDayCanPurchaseCount <=
                    this.limitActivity.totalTodayPurchasedCount + this.purchasedQuantity + this.num
            ) {
                return this.$toast(`[${this.productName}]今日已售罄，明天再来看看吧。`);
            } else if (
                this.limitActivity &&
                this.limitActivity.userEveryDayCanPurchaseCount <=
                    this.limitActivity.userTodayPurchasedCount + this.purchasedQuantity + this.num
            ) {
                return this.$toast(`[${this.productName}]今日已售罄，明天再来看看吧。`);
            }

            this.num++;

            // if (this.pageType == 0) {
            //     if (
            //         this.limitActivity &&
            //         this.limitActivity.totalEveryDayCanPurchaseCount <=
            //             this.limitActivity.totalTodayPurchasedCount +
            //                 this.purchasedQuantity +
            //                 this.num
            //     ) {
            //         return this.$toast(`[${this.productName}]今日已售罄，明天再来看看吧。`);
            //     } else if (
            //         this.limitActivity &&
            //         this.limitActivity.userEveryDayCanPurchaseCount <=
            //             this.limitActivity.userTodayPurchasedCount +
            //                 this.purchasedQuantity +
            //                 this.num
            //     ) {
            //         return this.$toast(`[${this.productName}]今日已售罄，明天再来看看吧。`);
            //     }
            // }
        },
        // 加入购物车
        addCart() {
            this.$util.eventStatistics({
                title: 'AddOnLineCart',
            });
            this.$util
                .post(ADD_PRODUCT_FOR_CART, {
                    accountId: this.accountId,
                    accountType: this.accountType,
                    productId: this.productId,
                    quantity: this.num,
                    storeCode: this.storeCode,
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        this.$util.pageBack();
                    }
                });
            // if (this.pageType == 0) {
            //     this.$util.eventStatistics({
            //         title: 'AddOnLineCart',
            //     });
            //     this.$util
            //         .post(ADD_PRODUCT_FOR_CART, {
            //             productId: this.productId,
            //             quantity: this.num,
            //             storeCode: this.storeCode,
            //         })
            //         .then(res => {
            //             if (res.success) {
            //                 this.$util.pageBack();
            //             }
            //         });
            // } else {
            //     if (
            //         this.takeoutOriginalProduct.productType == 2 ||
            //         this.takeoutOriginalProduct.productType == 3
            //     ) {
            //         if (this.takeoutOkStatus) {
            //             let localStorageData =
            //                 JSON.parse(localStorage.getItem(QZ_KFC_CART_NAME)) || [];
            //             if (
            //                 this.takeoutOriginalProduct.productType == 2 ||
            //                 this.takeoutOriginalProduct.productType == 3
            //             ) {
            //                 let tempCondimentItemList = [];
            //                 for (let i = 0; i < this.takeoutProductInfo.mandatory.length; i++) {
            //                     const item = this.takeoutProductInfo.mandatory[i];
            //                     for (let j = 0; j < item.condimentItemList.length; j++) {
            //                         const v = item.condimentItemList[j];
            //                         if (v.count > 0) {
            //                             tempCondimentItemList.push(
            //                                 Object.assign({}, v, {
            //                                     quantity: v.count,
            //                                     round: item.round,
            //                                 })
            //                             );
            //                         }
            //                     }
            //                 }
            //                 for (let i = 0; i < this.takeoutProductInfo.need2Select.length; i++) {
            //                     const item = this.takeoutProductInfo.need2Select[i];
            //                     for (let j = 0; j < item.condimentItemList.length; j++) {
            //                         const v = item.condimentItemList[j];
            //                         if (v.count > 0) {
            //                             tempCondimentItemList.push(
            //                                 Object.assign({}, v, {
            //                                     quantity: v.count,
            //                                     round: item.round,
            //                                 })
            //                             );
            //                         }
            //                     }
            //                 }
            //                 for (let i = 0; i < this.takeoutProductInfo.optional.length; i++) {
            //                     const item = this.takeoutProductInfo.optional[i];
            //                     for (let j = 0; j < item.condimentItemList.length; j++) {
            //                         const v = item.condimentItemList[j];
            //                         if (v.count > 0) {
            //                             tempCondimentItemList.push(
            //                                 Object.assign({}, v, {
            //                                     quantity: v.count,
            //                                     round: item.round,
            //                                 })
            //                             );
            //                         }
            //                     }
            //                 }
            //                 let productId = '';
            //                 let productGroupId = '';
            //                 let type = '';
            //                 if (this.takeoutOriginalProduct.productType == 2) {
            //                     productGroupId = this.takeoutOriginalProduct.productId;
            //                     productId =
            //                         this.takeoutOriginalProduct.groupItems[
            //                             this.takeoutSelectGroupIndex
            //                         ].productId;
            //                     type =
            //                         this.takeoutOriginalProduct.groupItems[
            //                             this.takeoutSelectGroupIndex
            //                         ].type;
            //                 } else if (this.takeoutOriginalProduct.productType == 3) {
            //                     productId = this.takeoutOriginalProduct.productId;
            //                     type = this.takeoutOriginalProduct.productType;
            //                 }
            //                 localStorageData.push(
            //                     Object.assign(
            //                         {},
            //                         {
            //                             productGroupId: productGroupId,
            //                             originalProductId: productId,
            //                             productId: productGroupId || productId,
            //                             productName: this.productName,
            //                             productImageUrl: this.productImageUrl,
            //                             num: this.num,
            //                             maxQty: this.maxQty,
            //                             price: this.price,
            //                             originalPrice: this.originalTotalPrice,
            //                             reduce_price: this.reduce_price,
            //                             type: type,
            //                             condimentItemList: tempCondimentItemList,
            //                         }
            //                     )
            //                 );
            //                 localStorage.setItem(
            //                     QZ_KFC_CART_NAME,
            //                     JSON.stringify(localStorageData)
            //                 );
            //                 this.$util.pageBack();
            //             } else {
            //             }
            //         }
            //     } else {
            //         this.$util.eventStatistics({
            //             title: 'AddTakeoutCart',
            //         });
            //         let localStorageData = JSON.parse(localStorage.getItem(QZ_KFC_CART_NAME)) || [];
            //         let addCartStatus = false;
            //         for (let i = 0; i < localStorageData.length; i++) {
            //             const localItem = localStorageData[i];
            //             localItem.items = localItem.items || [];
            //             if (localItem.productId == this.productId) {
            //                 if (localItem.maxQty >= localItem.num + this.num) {
            //                     localItem.num += this.num;
            //                     addCartStatus = true;
            //                     break;
            //                 } else {
            //                     return this.$toast(`该商品最大可购买数量为${localItem.maxQty}件`);
            //                 }
            //             }
            //             if (localItem._productId == this._productId) {
            //                 for (let j = 0; j < localItem.items.length; j++) {
            //                     const localSpecItem = localItem.items[j];
            //                     if (localSpecItem.productId == this.productId) {
            //                         localItem.num += this.num;
            //                         localSpecItem.num += this.num;
            //                         addCartStatus = true;
            //                         break;
            //                     }
            //                 }
            //                 if (addCartStatus) {
            //                     break;
            //                 }
            //             }
            //         }
            //         if (!addCartStatus) {
            //             let originalData = JSON.parse(
            //                 window.localStorage.getItem(QZ_KFC_PRODUCT_INFO)
            //             );
            //             originalData.num = Number(originalData.num) + this.num;
            //             if (this.specIndex != -1) {
            //                 for (let j = 0; j < this.specGroups.length; j++) {
            //                     const originalItem = this.specGroups[j];
            //                     if (originalItem.productId == this.productId) {
            //                         originalItem.num += this.num;
            //                     }
            //                 }
            //             }

            //             localStorageData.push(
            //                 Object.assign({}, originalData, {
            //                     items: this.specGroups,
            //                 })
            //             );
            //         }
            //         localStorage.setItem(QZ_KFC_CART_NAME, JSON.stringify(localStorageData));
            //         this.$util.pageBack();
            //     }
            // }
        },
        // 切换group商品
        switchGroupProduct(index) {
            let item = this.takeoutOriginalProduct.groupItems[index];
            if (item.saleOut) {
                return this.$toast('已售罄');
            }
            this.takeoutSelectGroupIndex = index;
            if (item.type == 1) {
                this.takeoutOkStatus = true;
                this.price = item.price;
                this.originalTotalPrice = item.originalPrice;
            } else {
                this.takeoutProductInfo = {
                    mandatory: [],
                    need2Select: [],
                    optional: [],
                };

                let list = item.condimentRoundList;
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    this.takeoutProductClassification(item);
                }
                this.inspectTakeoutProductSelectStatus();
            }
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
@bottomHeight: .pxToRem(176) [];
@bottomPriceLineHeight: .pxToRem(88) [];
@bottomAddCartHeight: calc(.pxToRem(176) [] - .pxToRem(88) []);

.page {
    background: white;
    min-height: 100%;
    box-sizing: border-box;
}
.padding-bottom {
    height: .pxToRem(228) [];
}
.product-img {
    width: 100%;
}
.product-name {
    width: 100%;
    box-sizing: border-box;
    padding: .pxToRem(20) [] .pxToRem(40) [];
    line-height: .pxToRem(60) [];
    font-size: .pxToRem(40) [];
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: .pxToRem(40) [];
        right: .pxToRem(40) [];
        height: .pxToRem(1) [];
        background: #000;
    }
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: .pxToRem(40) [];
        right: .pxToRem(40) [];
        height: .pxToRem(1) [];
        background: #000;
    }
}
.product-desc {
    padding: .pxToRem(30) [] .pxToRem(40) [];
    font-size: .pxToRem(26) [];
    line-height: .pxToRem(40) [];
    color: rgba(0, 0, 0, 0.9);
    white-space: pre-wrap;
}
.product-box,
.spec-group-box {
    .title {
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        color: rgba(0, 0, 0, 0.9);
        font-size: .pxToRem(30) [];
        font-weight: 600;
        margin-top: .pxToRem(30) [];
        margin-bottom: .pxToRem(30) [];
        text-indent: .pxToRem(40) [];
    }
    .product-list {
        .product-item {
            width: 100%;
            height: .pxToRem(88) [];
            margin-bottom: .pxToRem(10) [];
            .img {
                .base-center-y;
                left: .pxToRem(120) [];
                max-width: .pxToRem(110) [];
                max-height: .pxToRem(80) [];
            }
            .name {
                .base-center-y;
                left: .pxToRem(250) [];
                right: .pxToRem(120) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(28) [];
                color: #1f1f1f;
                .overflow(2);
                .group-name {
                    font-weight: bold;
                }
            }

            .quantity-box {
                .base-center-y;
                right: .pxToRem(28) [];
                height: .pxToRem(56) [];
                .reduce {
                    position: absolute;
                    top: 0;
                    left: 0;
                    .sq(56);
                    z-index: 1;
                }
                .quantity {
                    height: .pxToRem(56) [];
                    line-height: .pxToRem(56) [];
                    padding: 0 .pxToRem(74) [];
                }
                .plus {
                    position: absolute;
                    top: 0;
                    right: 0;
                    .sq(56);
                    z-index: 1;
                    &.max {
                        opacity: 0.4;
                        filter: grayscale(100%);
                        filter: gray;
                    }
                }
            }
            .sale-out {
                .base-center-y;
                right: .pxToRem(28) [];
                color: white;
                background-color: rgba(0, 0, 0, 0.3);
                height: .pxToRem(42) [];
                line-height: .pxToRem(42) [];
                font-size: .pxToRem(24) [];
                padding: 0 .pxToRem(16) [];
                border-radius: .pxToRem(21) [];
            }
            .num {
                .base-center-y;
                right: .pxToRem(40) [];
                line-height: .pxToRem(38) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.9);
                font-weight: 600;
            }
            &::before {
                content: ' ';
                .base-center-y;
                left: .pxToRem(50) [];
                .sq(16);
                border-radius: 100%;
                background: rgba(0, 0, 0, 0.15);
            }
            &::after {
                content: ' ';
                position: absolute;
                left: .pxToRem(58) [];
                bottom: .pxToRem(-25) [];
                width: .pxToRem(1) [];
                height: .pxToRem(48) [];
                background: rgba(0, 0, 0, 0.15);
            }
            &:last-child {
                &::after {
                    width: 0;
                    height: 0;
                }
            }
        }
        .spec-group-item {
            padding-left: .pxToRem(40) [];
            padding-right: .pxToRem(40) [];
            .spec-group-name {
                display: inline-block;
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                border-radius: .pxToRem(8) [];
                font-size: .pxToRem(26) [];
                padding: 0 .pxToRem(20) [];
                margin-right: .pxToRem(12) [];
                margin-bottom: .pxToRem(12) [];
                box-sizing: border-box;
                background-color: #ffffff;
                color: rgba(0, 0, 0, 0.5);
                border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.5);
                &.select {
                    background: #fdf0f0;
                    color: @theme_color_first;
                    border: .pxToRem(1) [] solid @theme_color_first;
                }
            }
        }
    }

    .group-list {
        padding-left: .pxToRem(40) [];
        padding-right: .pxToRem(40) [];
        .group-item {
            display: inline-block;
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            border-radius: .pxToRem(8) [];
            font-size: .pxToRem(26) [];
            padding: 0 .pxToRem(20) [];
            margin-right: .pxToRem(12) [];
            margin-bottom: .pxToRem(12) [];
            box-sizing: border-box;
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.5);
            border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.5);
            &.select {
                background: #fdf0f0;
                color: @theme_color_first;
                border: .pxToRem(1) [] solid @theme_color_first;
            }
        }
    }
}
.product-detail-list {
    width: 100%;
    font-size: 0;
    img {
        width: 100%;
    }
}
.bottom {
    width: 100%;
    height: @bottomHeight;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    line-height: @bottomHeight;
    border-top: .pxToRem(1) [] solid #e5e5e5;
    box-shadow: 0 .pxToRem(8) [] .pxToRem(15) [] .pxToRem(15) [] rgba(4, 4, 4, 0.1);
    border-radius: .pxToRem(40) [] .pxToRem(40) [] 0 0;
    overflow: hidden;
    .price-line {
        height: @bottomPriceLineHeight;
        background: white;
        .total {
            .base-center-y;
            left: .pxToRem(45) [];
            font-weight: 600;
            font-size: .pxToRem(40) [];
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            color: rgba(0, 0, 0, 0.9);
            padding-left: .pxToRem(28) [];
            &:before {
                content: '¥';
                position: absolute;
                left: 0;
                bottom: .pxToRem(-4) [];
                width: .pxToRem(28) [];
                text-align: center;
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(28) [];
                font-weight: 400;
            }
        }
        .quantity-box {
            .base-center-y;
            right: .pxToRem(28) [];
            height: .pxToRem(56) [];
            .reduce {
                position: absolute;
                top: 0;
                left: 0;
                .sq(56);
                z-index: 1;
            }
            .num {
                height: .pxToRem(56) [];
                line-height: .pxToRem(56) [];
                padding: 0 .pxToRem(74) [];
            }
            .plus {
                position: absolute;
                top: 0;
                right: 0;
                .sq(56);
                z-index: 1;
            }
        }
    }
    .btn-submit {
        height: .pxToRem(88) [];
        line-height: .pxToRem(88) [];
        text-align: center;
        color: white;
        font-size: .pxToRem(28) [];
        background: @theme_color_first;
    }
}
.cart-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    .cart-line {
        height: .pxToRem(100) [];
        width: .pxToRem(702) [];
        margin: 0 auto .pxToRem(48) [];
        .coupon-box {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: .pxToRem(144) [];
            margin-left: .pxToRem(50) [];
            background: #333333;
            transform: skewX(-10deg);
            border-radius: 0 .pxToRem(12) [] .pxToRem(12) [] 0;
            .content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transform: skewX(10deg);
                .title {
                    line-height: .pxToRem(30) [];
                    font-size: .pxToRem(28) [];
                    font-weight: 600;
                    color: white;
                }
                .coupon-count {
                    line-height: .pxToRem(26) [];
                    font-size: .pxToRem(24) [];
                    margin-top: .pxToRem(8) [];
                    color: white;
                    font-weight: 600;
                }
            }
            &::before {
                content: ' ';
                position: absolute;
                left: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background: #333333;
                transform: skewX(10deg);
                border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
            }
        }
        .cart-price {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: .pxToRem(204) [];
            background-color: #d90209;
            transform: skewX(-10deg);
            margin-right: .pxToRem(50) [];
            border-radius: .pxToRem(12) [] 0 0 .pxToRem(12) [];
            transition-property: left, border-radius;
            transition-duration: 0.1s;
            .content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transform: skewX(10deg);
                .price-box {
                    position: absolute;
                    left: 0;
                    right: .pxToRem(100) [];
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    margin-left: .pxToRem(12) [];
                    padding-left: .pxToRem(100) [];
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .product-count {
                        position: absolute;
                        top: .pxToRem(8) [];
                        left: .pxToRem(70) [];
                        background: #333333;
                        color: white;
                        width: .pxToRem(40) [];
                        text-align: center;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        border-radius: 100%;
                        font-size: .pxToRem(24) [];
                        font-weight: 600;
                    }
                    .price {
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(48) [];
                        font-size: .pxToRem(42) [];
                        font-weight: 600;
                        color: white;
                        display: flex;
                        align-items: baseline;
                        .money-discount {
                            display: none;
                            margin-left: .pxToRem(12) [];
                            height: .pxToRem(32) [];
                            line-height: .pxToRem(32) [];
                            font-size: .pxToRem(24) [];
                        }
                    }
                    .delivery-fee {
                        display: none;
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(28) [];
                        font-size: .pxToRem(26) [];
                        font-weight: 600;
                        color: white;
                    }
                    &::before {
                        content: ' ';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: .pxToRem(100) [];
                        height: .pxToRem(100) [];
                        background: url('~@/assets/icons/icon-cart.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
                .submit-btn {
                    position: absolute;
                    right: .pxToRem(-20) [];
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                        line-height: .pxToRem(38) [];
                        font-size: .pxToRem(36) [];
                        color: white;
                        font-weight: 600;
                    }
                    .tip {
                        line-height: .pxToRem(26) [];
                        font-size: .pxToRem(24) [];
                        color: white;
                        font-weight: 600;
                        text-align: center;
                    }
                }
                .no-select-product-tip {
                    line-height: .pxToRem(100) [];
                    margin-left: .pxToRem(112) [];
                    font-size: .pxToRem(36) [];
                    color: white;
                    font-size: 600;
                }
            }
            &::before {
                content: ' ';
                position: absolute;
                right: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background-color: #d90209;
                transform: skewX(10deg);
                border-radius: 0 .pxToRem(50) [] .pxToRem(50) [] 0;
            }
        }
    }
    &.open-cart .coupon-box {
        left: .pxToRem(2) [];
        top: .pxToRem(2) [];
        bottom: .pxToRem(2) [];
    }
    &.open-cart .cart-line .cart-price {
        left: 0;
        transform: skewX(0deg);
        border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
        .content {
            transform: skewX(0deg);
            .price-box .price .money-discount {
                display: unset;
            }
        }
        &::before {
            transform: skewX(0deg);
        }
    }
    &.not-product .cart-line .cart-price {
        background-color: #979797;
        .content .price-box .price {
            display: none;
        }
        .content .price-box .delivery-fee {
            display: none;
        }
        .content .submit-btn {
            display: none;
        }
        &::before {
            background-color: #979797;
        }
    }
    .quantity-line {
        width: .pxToRem(702) [];
        height: .pxToRem(80) [];
        margin: 0 auto;
        background: #fdf0f0;
        border-radius: .pxToRem(40) [] .pxToRem(40) [] 0 0;
        &::before {
            content: ' ';
            position: absolute;
            bottom: .pxToRem(-45) [];
            left: 0;
            right: 0;
            height: .pxToRem(50) [];
            background-color: #fdf0f0;
        }
        .quantity-box {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: .pxToRem(28) [];
            height: .pxToRem(56) [];
            .reduce {
                position: absolute;
                top: 0;
                left: 0;
                width: .pxToRem(56) [];
                height: .pxToRem(56) [];
                z-index: 1;
            }
            .quantity {
                height: .pxToRem(56) [];
                line-height: .pxToRem(56) [];
                padding: 0 .pxToRem(74) [];
            }
            .plus {
                position: absolute;
                top: 0;
                right: 0;
                width: .pxToRem(56) [];
                height: .pxToRem(56) [];
                z-index: 1;
                &.max {
                    opacity: 0.4;
                    filter: grayscale(100%);
                    filter: gray;
                }
            }
        }
    }
}

.takeout {
    .product-list {
        .product-item {
            .name {
                right: .pxToRem(200) [];
                .price {
                    color: @theme_color_first;
                    font-size: .pxToRem(24) [];
                    font-weight: bold;
                }
            }
        }
    }
    .bottom {
        &.takeout-select-no {
            height: @bottomAddCartHeight;
            border-radius: 0;
            .price-line {
                display: none;
            }
            .btn-submit {
                filter: gray;
                filter: grayscale(100%);
            }
        }
    }
}
</style>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.show-original-price {
    .detail {
        .bottom {
            .price-line {
                .price-text {
                    position: absolute;
                    top: .pxToRem(12) [];
                    left: .pxToRem(45) [];
                    height: .pxToRem(40) [];
                    line-height: .pxToRem(40) [];
                    white-space: nowrap;
                    font-weight: 600;
                    font-size: .pxToRem(40) [];
                }
                .total {
                    top: .pxToRem(12) [];
                    left: .pxToRem(170) [];
                    transform: unset;
                }
                .actual-payment {
                    position: absolute;
                    bottom: .pxToRem(2) [];
                    left: .pxToRem(45) [];
                    height: .pxToRem(32) [];
                    line-height: .pxToRem(32) [];
                    font-size: .pxToRem(24) [];
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }
}
</style>
